import { AppState } from 'base'
import { Modal } from 'shared'
import { UserRequestApi, EventApi, User, AssetManager } from 'api'
import * as controllers from 'controllers'

class Application
  constructor: ->
    @state = new AppState
    window.appState = @state

  go: (path)       -> if @history then @history.push(path) else @goDirect(path)
  goDirect: (path) -> if (AppData.settings.embed == true) then location.replace "/app?embed=true##{path}" else location.replace "/app##{path}"

  openChat: ->
    App.state.deleteObjectSilent('chat:showLast')
    @go "#{@state.getObject('base')}/chat"

  signOut: ({ redirectPath = '/app#login' } = { }) ->
    console.info 'signing out', redirectPath
    Backend.request 'DELETE', '/auth'
    User.signOut()
    window.location = redirectPath
    window.location.reload()

  @prop 'path', -> location.hash.replace('#', '')

  @prop 'queryParams', ->
    return null if !window.URLSearchParams

    path = @path
    return null unless path.includes('?')

    parts = path.split('?')
    query = parts[parts.length - 1]
    return new URLSearchParams(query)

  @prop 'nestingLevel',        -> location.hash.replace('#/', '').split('/').length

  @prop 'authToken',           -> localStorage.rlAuthToken
  @prop 'isSignedIn',          -> @authToken?
  @prop 'user',                -> User.current

  @prop 'Modal',               -> Modal
  @prop 'User',                -> User
  @prop 'api',                 -> { UserRequestApi, EventApi }
  @prop 'controllers',         -> controllers
  @prop 'assets',              -> AssetManager
  @prop 'guestPassSettings',   -> AppData.guestPass ? { }
  @prop 'router',              -> controllers.Router

  @prop 'data',                -> AppData
  @prop 'googleApiKey',        -> AppData.settings.googleApiKey
  @prop 'timeZoneOffset',      -> AppData.settings.timeZone
  @prop 'timeZoneName',        -> AppData.settings.timeZoneName
  @prop 'deploymentName',      -> AppData.settings.deploymentName
  @prop 'stripePublicKey',     -> AppData.settings.stripePublicKey
  @prop 'squareApplicationId', -> AppData.settings.squareApplicationId
  @prop 'embed',               -> AppData.settings.embed

  @prop 'now',                 -> moment().utcOffset(@timeZoneOffset)
  @prop 'todayDate',           -> new Date(Date.parse(@todayDateString))
  @prop 'todayDateString',     -> @now.format("YYYY-MM-DD")
  @prop 'tomorrowDateString',  -> @now.add(1, 'day').format("YYYY-MM-DD")

  @prop 'isIOS',               -> /iPad|iPhone|iPod/.test(navigator.userAgent)
  @prop 'devMode',             -> location.hostname.includes('localhost')

  @prop 'canNestChat', ->
    nestingLevel = @nestingLevel
    @path.includes('/menu') && nestingLevel <= 3 || nestingLevel <= 2

  @prop 'disableHomeFeed',        -> shouldForceGuestMode()
  @prop 'forceGuestMode',         -> shouldForceGuestMode()
  @prop 'showsButtonsOnEachFeed', -> shouldForceGuestMode()
  @prop 'loginOption', -> "#{@data.settings.loginOption}"

  @prop 'isBigSky', -> AppData.settings.deploymentName is 'lmlcbasecamp'

export default new Application

shouldForceGuestMode = ->
  AppData.settings.embed == true ||
  location.host.includes('discoverbrambleton.com') ||
  location.host.includes('localhost:3000') ||
  application.loginOption.includes('guest-auto') ||
  true

metaParamCache = {}
metaParam = (name) ->
  metaParamCache[name] = metaParamCache[name] ? document.querySelector("meta[name=#{name}]")?.content